import React from "react";
import { Box, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import PropTypes from "prop-types";
import TypingEffect from "components/typing";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MessageBox = ({ message, scrollContainerRef, last }) => {
  const isUser = message.role === "user";
  const backgroundColor = isUser ? "#c8c8ee" : "#ffffff";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        {isUser ? <AccountCircleIcon fontSize="large" /> : <SmartToyIcon fontSize="large" />}
        <Typography flex="1" color="textSecondary" sx={{ ml: 2, fontSize: 16 }}>
          {message.model}
        </Typography>
        <Typography color="textSecondary" sx={{ ml: 2, fontSize: 14 }}>
          {message.time}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,
          backgroundColor: backgroundColor,
          padding: "10px",
          borderRadius: "10px",
          maxWidth: "100%",
          wordBreak: "break-word",
          position: "relative",
        }}
      >
        {isUser || !last ? (
          <Box
            sx={{
              fontSize: 18,
              mx: 1.5,
              my: 1,
              overflowWrap: "break-word",
              wordBreak: "break-word",
              "& pre": {
                whiteSpace: "pre-wrap",
              },
              "& code": {
                fontSize: "16px",
              },
            }}
          >
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.content}</ReactMarkdown>
          </Box>
        ) : (
          <TypingEffect text={message.content} speed={20} scrollContainerRef={scrollContainerRef} />
        )}
        {!isUser && (
          <Typography
            sx={{
              fontSize: 10,
              color: "gray",
              position: "absolute",
              bottom: 2,
              right: 10,
            }}
          >
            由AI生成, 仅供参考
          </Typography>
        )}
      </Box>
    </Box>
  );
};

MessageBox.defaultProps = {
  message: {},
  scrollContainerRef: {},
  last: false,
};

MessageBox.propTypes = {
  message: PropTypes.object.isRequired,
  scrollContainerRef: PropTypes.object.isRequired,
  last: PropTypes.bool.isRequired,
};

export default MessageBox;
