import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

const TypingEffect = ({ text, speed, scrollContainerRef }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const textLength = text.length;
    const typeText = () => {
      if (currentIndex < textLength) {
        const nextChar = text[currentIndex];
        setDisplayedText((prev) => prev + nextChar);
        currentIndex += 1;
        setTimeout(typeText, speed);
      }
    };
    typeText();
    return () => {
      clearTimeout(typeText);
    };
  }, [text, speed]);

  useEffect(() => {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [displayedText, scrollContainerRef]);

  return (
    <Box
      sx={{
        fontSize: 18,
        mx: 1.5,
        my: 1,
        overflowWrap: "break-word",
        wordBreak: "break-word",
        "& pre": {
          whiteSpace: "pre-wrap",
        },
        "& code": {
          fontSize: "16px",
        },
      }}
    >
      <ReactMarkdown>{displayedText}</ReactMarkdown>
    </Box>
  );
};

TypingEffect.defaultProps = {
  text: "",
  speed: 50,
  scrollContainerRef: {},
};

TypingEffect.propTypes = {
  text: PropTypes.string.isRequired,
  speed: PropTypes.number.isRequired,
  scrollContainerRef: PropTypes.object.isRequired,
};

export default TypingEffect;
