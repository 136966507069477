/**

*/
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Typography, Box } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";

import brand from "assets/images/logo1.png";

function BasicLayout({ image, children }) {
  return (
    <PageLayout>
      <Box position="absolute" width="100%" height="15vh">
        <Box display="flex" alignItems="center" mt={3} ml={5}>
          <Box component="img" src={brand} alt="Brand" width="3rem" />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography px={2} component="h6" variant="h4" fontWeight="regular" color="#000000">
              星临星觉
            </Typography>
            <Typography px={1} component="h6" variant="h6" fontWeight="regular" color="#000000">
              大模型服务平台
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        mt={13}
        position="absolute"
        width="100%"
        height="80%"
        sx={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box
        position="absolute"
        top="50%"
        left="60%"
        width="25vw"
        height="auto"
        sx={{
          transform: "translateY(-50%)",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: 1,
        }}
      >
        {children}
      </Box>
      <Footer />
    </PageLayout>
  );
}

BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
