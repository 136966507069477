/**

*/
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiKeyDialog from "examples/APIDialog";
import CodeSample from "examples/CodeSample";
import { CircularProgress, Button, Box, InputAdornment } from "@mui/material";
import { TextField, List, ListItem, Typography, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import SearchIcon from "@mui/icons-material/Search";
import KeyIcon from "@mui/icons-material/Key";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import brandWhite from "assets/images/apple-icon.png";
import ModelDetail from "examples/ModelDetail";
import { useMaterialUIController } from "context";

function ModelsView() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;

  const [apiDialogOpen, setApiDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setApiDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setApiDialogOpen(false);
  };

  const [selectedModel, setSelectedModel] = useState(null);

  const [codeSampleOpen, setCodeSampleOpen] = useState(false);
  const handleOpenCodeSample = (m) => {
    setSelectedModel(m);
    setCodeSampleOpen(true);
  };
  const handleCloseCodeSample = () => {
    setCodeSampleOpen(false);
  };

  const [modelDetailOpen, setModelDetailOpen] = useState(false);
  const handleOpenModelDetail = (m) => {
    setSelectedModel(m);
    setModelDetailOpen(true);
  };
  const handleCloseModelDetail = () => {
    setModelDetailOpen(false);
  };

  const [activeTab, setActiveTab] = useState("modelFunctions");
  const [searchQuery, setSearchQuery] = useState("");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleSearch = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      let result = [];
      models.forEach((model) => {
        let name = model.name;
        if (name.includes(searchQuery)) {
          result.push(model);
        }
      });
      setShowModels(result);
    }
  };
  const modelSet = [];

  let modelFunction = [
    {
      value: "ALL",
      text: "全部模型",
    },
    {
      value: "TEXT_TO_TEXT",
      text: "文字生成文字",
    },
    {
      value: "TEXT_TO_SPEECH",
      text: "文字生成语音",
    },
    {
      value: "SPEECH_TO_TEXT",
      text: "语音生成文字",
    },
    {
      value: "TEXT_TO_IMAGE",
      text: "文字生成图片",
    },
    {
      value: "IMAGE_TO_IMAGE",
      text: "图片生成图片",
    },
  ];

  const [models, setModels] = useState([]);
  const [showModels, setShowModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setError(false);

  const [filter, setFilter] = useState("ALL");

  if (parseInt(process.env.REACT_APP_DEVELOP) === 0) {
    modelFunction = [
      {
        value: "TEMP_USED",
        text: "文字生成文字",
      },
    ];
  }

  const handleFilterChange = (f) => {
    setFilter(f);
    getModels(f);
  };

  const navigate = useNavigate();
  const handleTry = (m) => {
    if (m.model_type !== "TEXT_TO_TEXT") {
      setInfoLabel("模型选择");
      setInfoText("当前只支持选择文字生产文字类型的模型，请重新选择");
      setError(true);
      return;
    }
    navigate("/models_test", { state: [m] });
  };

  const getModels = async (f) => {
    setLoading(true);
    try {
      let url;
      const apiUrl = process.env.REACT_APP_API_URL;
      if (!f || f === "ALL") {
        url = `${apiUrl}/model/findAll`;
      } else if (f === "TEMP_USED") {
        url = `${apiUrl}/model/findConformanceByType?model_type=TEXT_TO_TEXT`;
      } else {
        url = `${apiUrl}/model/findByType?model_type=${f}`;
      }
      let response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        setModels(response.data);
        setShowModels(response.data);
      } else {
        setInfoLabel("模型加载");
        setInfoText("获取模型列表出错");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("模型加载");
      setInfoText("获取模型列表出错");
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      if (parseInt(process.env.REACT_APP_DEVELOP) === 1) {
        getModels(filter);
      } else {
        getModels("TEMP_USED");
      }
    }
  }, [user]);

  return (
    <DashboardLayout>
      <Box height="95vh">
        <Box sx={{ bgcolor: "#ffffff" }}>
          <Box display="flex" alignItems="center" pt={4}>
            <Typography px={4} component="h6" variant="h4" fontWeight="regular" color={"#4b26df"}>
              模型广场
            </Typography>
            <KeyIcon fontSize="small" pt={4}>
              KeyIcon
            </KeyIcon>
            <Typography
              px={1}
              mt={0.5}
              component="h6"
              variant="subtitle2"
              fontWeight="light"
              color={"#4b26df"}
              sx={{ cursor: "pointer" }}
              onClick={handleOpenDialog}
            >
              查看我的API KEY
            </Typography>
          </Box>
          <Typography
            pt={1}
            px={4}
            pb={4}
            component="h6"
            variant="caption"
            fontWeight="light"
            color={"#a0a0a0"}
          >
            模型广场提供超过80种模型选择，包括第三方开源模型、行业领域模型等，您可以按照业务需求选择合适的模型接入
          </Typography>
        </Box>
        <Box mt={2} display="flex" alignItems="flex-start" height="90%">
          <Box
            width="15vw"
            height="100%"
            display="flex"
            flexDirection="column"
            sx={{
              borderRadius: 1,
              bgcolor: "#ffffff",
              overflow: "hidden",
            }}
          >
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="从全部模型里搜索"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <Button
                onClick={() => handleTabChange("modelSeries")}
                sx={{
                  color: activeTab === "modelSeries" ? "#000000" : "#636363",
                  flex: 1,
                  borderRadius: 0,
                  borderBottom: activeTab === "modelSeries" ? "2px solid #000" : "none",
                }}
              >
                模型系列
              </Button>
              <Button
                onClick={() => handleTabChange("modelFunctions")}
                sx={{
                  color: activeTab === "modelFunctions" ? "#000000" : "#636363",
                  flex: 1,
                  borderRadius: 0,
                  borderBottom: activeTab === "modelFunctions" ? "2px solid #000" : "none",
                }}
              >
                模型功能
              </Button>
            </Box>
            <Box sx={{ overflowY: "auto", flexGrow: 1, p: 2 }}>
              <List>
                {activeTab === "modelSeries"
                  ? modelSet.map((item, index) => (
                      <ListItem key={index}>
                        <Typography>{item.text}</Typography>
                      </ListItem>
                    ))
                  : modelFunction.map((item, index) => (
                      <ListItem key={index}>
                        <Button
                          onClick={() => handleFilterChange(item.value)}
                          sx={{
                            color: filter === item.value ? "#0126ca" : "#000000",
                            fontSize: "1rem",
                            fontWeight: "regular",
                          }}
                        >
                          {item.text}
                        </Button>
                      </ListItem>
                    ))}
              </List>
            </Box>
          </Box>
          <Box width="2vw"></Box>
          <Box
            flex={1}
            height="100%"
            overflow="auto"
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(45%, 1fr))"
            gap={2}
            alignContent="start"
          >
            {loading && <CircularProgress sx={{ mt: 2 }} />}
            {showModels.map((object, index) => (
              <Box
                key={index}
                maxWidth="100%"
                height="auto"
                position="relative"
                sx={{
                  borderRadius: 5,
                  bgcolor: "#ffffff",
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  "&:hover": {
                    bgcolor: "#ececec",
                  },
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box
                    component="img"
                    sx={{
                      height: 40,
                      width: 40,
                      mr: 2,
                    }}
                    src={brandWhite}
                  />
                  <Typography component="h6" variant="h5" fontWeight="normal" color={"#000000"}>
                    {object.name}
                  </Typography>
                </Box>
                <Box mt={1} mb={2} ml={7}>
                  <Typography
                    component="h6"
                    variant="subtitle2"
                    fontWeight="light"
                    color={"#838383"}
                    sx={{
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {object.description}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Typography
                    component="h6"
                    variant="subtitle2"
                    fontWeight="light"
                    color={"#808080"}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "#4b26df",
                      },
                      mr: 4,
                    }}
                    onClick={() => handleOpenModelDetail(object)}
                  >
                    查看详情
                  </Typography>
                  <Typography
                    component="h6"
                    variant="subtitle2"
                    fontWeight="light"
                    color={"#808080"}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "#4b26df",
                      },
                      mr: 4,
                    }}
                    onClick={() => handleOpenCodeSample(object)}
                  >
                    API调用示例
                  </Typography>
                  <Typography
                    component="h6"
                    variant="subtitle2"
                    fontWeight="light"
                    color={"#808080"}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "#4b26df",
                      },
                      mr: 1,
                    }}
                    onClick={() => handleTry(object)}
                  >
                    体验
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6">
              {infoLabel}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {infoText}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <ApiKeyDialog open={apiDialogOpen} onClose={handleCloseDialog} />
      <CodeSample
        open={codeSampleOpen}
        onClose={handleCloseCodeSample}
        model={selectedModel}
        onAPI={handleOpenDialog}
        onDetail={handleOpenModelDetail}
      />
      <ModelDetail
        open={modelDetailOpen}
        onClose={handleCloseModelDetail}
        model={selectedModel}
        onTry={handleTry}
        onSample={handleOpenCodeSample}
      />
    </DashboardLayout>
  );
}

export default ModelsView;
