/**

*/
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ModelsView from "layouts/models_view";
import ModelsTest from "layouts/models_test";
import AppsView from "layouts/apps_view";
import AppsOwn from "layouts/apps_own";
import AppDetail from "layouts/apps_own/app_detail";

import Icon from "@mui/material/Icon";
import ModelsReview from "layouts/models_review";
import ApiSample from "layouts/apps_own/api_sample";
import NewApi from "layouts/apps_own/new_api";

const routes = [
  {
    type: "title",
    name: "title1",
    key: "title1",
    title: "模型中心",
    mustShow: true,
  },
  {
    type: "collapse",
    name: "模型广场",
    key: "models_view",
    icon: <Icon fontSize="small">domain</Icon>,
    route: "/models_view",
    component: <ModelsView />,
    mustShow: true,
  },
  {
    type: "collapse",
    name: "模型体验",
    key: "models_test",
    icon: <Icon fontSize="small">forum</Icon>,
    route: "/models_test",
    component: <ModelsTest />,
    mustShow: true,
  },
  {
    type: "collapse",
    name: "后台审核",
    key: "models_review",
    icon: <Icon fontSize="small">preview</Icon>,
    route: "/models_review",
    component: <ModelsReview />,
    mustShow: true,
  },
  {
    type: "divider",
    name: "divider1",
    key: "divider1",
    mustShow: true,
  },
  {
    type: "title",
    name: "title2",
    key: "title2",
    title: "应用中心",
    mustShow: true,
  },
  {
    type: "collapse",
    name: "应用广场",
    key: "apps_view",
    icon: <Icon fontSize="small">apps</Icon>,
    route: "/apps_view",
    component: <AppsView />,
    mustShow: false,
  },
  {
    type: "collapse",
    name: "我的应用",
    key: "apps_own",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/apps_own",
    component: <AppsOwn />,
    mustShow: true,
  },
  {
    type: "collapse",
    name: "登录",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    mustShow: false,
  },
  {
    type: "collapse",
    name: "注册",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    mustShow: false,
  },
  {
    type: "collapse",
    name: "App详情",
    key: "app_detail",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/apps_own/app_detail",
    component: <AppDetail />,
    mustShow: false,
  },
  {
    type: "collapse",
    name: "Api调用示例",
    key: "api_sample",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/apps_own/app_detail/api_sample",
    component: <ApiSample />,
    mustShow: false,
  },
  {
    type: "collapse",
    name: "新建Api",
    key: "new_api",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/apps_own/app_detail/new_api",
    component: <NewApi />,
    mustShow: false,
  },
];

export default routes;
