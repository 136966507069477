import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "examples/ConfirmDialog";
import CreateApiKeyDialog from "./CreateAPIDialog";
import axios from "axios";
import ErrorModal from "examples/ErrorModal";
import { useMaterialUIController } from "context";
import MessageModal from "examples/MessageModal";

const ApiKeyDialog = ({ open, onClose }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;

  const [loading, setLoading] = useState(false);

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleOpenErrorModal = () => {
    setErrorModalOpen(true);
  };
  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };
  const [data, setData] = useState([]);
  const loadData = async () => {
    setLoading(true);
    let apps = {};
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/app/findAll`;
      let response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        let appsGot = response.data;
        appsGot.forEach((app) => {
          if (app.userid === user.name) {
            apps[app.id] = app.name;
          }
        });
      } else {
        handleOpenErrorModal();
        return;
      }
    } catch (error) {
      console.log(error);
      handleOpenErrorModal();
      return;
    }
    try {
      let appid = Object.keys(apps);
      let intid = appid.map(Number);
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/apiKey/findByUser`;
      let response = await axios.post(url, {
        appids: intid,
      });
      if (response.status === 200) {
        let keys = response.data;
        let data = [];
        keys.forEach((key) => {
          let d = new Date(key.updated_time);
          let timeString = d.toLocaleString();
          data.push({
            id: key.id,
            api: key.api_key,
            showApi: false,
            app_id: key.app_id,
            app_name: apps[key.app_id],
            info: key.description,
            time: timeString,
          });
        });
        setData(data);
      } else {
        handleOpenErrorModal();
      }
    } catch (error) {
      console.log(error);
      handleOpenErrorModal();
    } finally {
      setLoading(false);
    }
  };

  const showApi = (index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        showApi: !newData[index].showApi,
      };
      return newData;
    });
  };

  const encryptString = (s) => {
    const start = s.slice(0, 4);
    const end = s.slice(-4);
    const maskedString = start + "*".repeat(s.length - 8) + end;
    return maskedString;
  };

  const [create, setCreate] = useState(false);
  const createApi = (apiName, apiInfo) => {
    loadData();
    setCreate(false);
  };
  const handleOpenCreate = () => {
    setCreate(true);
  };
  const handleCloseCreate = () => {
    setCreate(false);
  };

  const [selectedApi, setSelectedApi] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDelete = () => {
    setDeleteModalOpen(true);
  };
  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };
  const deleteApi = async () => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.delete(`${apiUrl}/apiKey/${data[selectedApi].id}`, {
        headers: {
          Authorization: "Bearer sk-nQpY9Cw46m3bbiz2jFY1sFDc5EPbH5RwSMWbsmXSjjyEK1ic",
        },
      });
      if (response.status === 200) {
        handleOpenModal();
        loadData();
      } else {
        handleOpenDelete();
      }
    } catch (error) {
      console.log(error);
      handleOpenDelete();
    } finally {
      setLoading(false);
      setConfirm(false);
    }
  };
  const handleOpenConfirm = (index) => {
    setSelectedApi(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (user && open) {
      loadData();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        我的API-KEY
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#4b26df",
            color: "#ffffff",
            float: "right",
            mb: 2,
          }}
          onClick={handleOpenCreate}
        >
          创建API KEY
        </Button>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow>
                <TableCell>API KEY</TableCell>
                <TableCell>归属应用</TableCell>
                <TableCell>描述</TableCell>
                <TableCell>创建时间</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.showApi ? item.api : encryptString(item.api)}</TableCell>
                  <TableCell>{item.app_name}</TableCell>
                  <TableCell>{item.info}</TableCell>
                  <TableCell>{item.time}</TableCell>
                  <TableCell>
                    <Typography
                      component="span"
                      sx={{
                        color: "#4b26df",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#808080",
                        },
                        fontSize: 16,
                      }}
                      onClick={() => showApi(index)}
                    >
                      {item.showApi ? "隐藏" : "查看"}
                    </Typography>
                    {" | "}
                    <Typography
                      component="span"
                      sx={{
                        color: "#4b26df",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#808080",
                        },
                        fontSize: 16,
                      }}
                      onClick={() => handleOpenConfirm(index)}
                    >
                      删除
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title="删除API-Key"
        info="是否确认删除当前选择的API-Key? API-Key被删除后无法恢复"
        confirm={deleteApi}
      />
      <CreateApiKeyDialog open={create} onClose={handleCloseCreate} onSubmit={createApi} />
      <MessageModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={`已经成功删除选择的Api-Key`}
        time={1000}
      />
      <ErrorModal
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        message={`我的Api-Keys加载出错，请重试`}
        time={1000}
      />
      <ErrorModal
        open={deleteModalOpen}
        onClose={handleCloseDelete}
        message={`删除Api-Key失败，请重试`}
        time={1000}
      />
    </Dialog>
  );
};

ApiKeyDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

ApiKeyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ApiKeyDialog;
