/**

*/
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Box, IconButton, Typography, Button, TextField } from "@mui/material";
import { Grid, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import SendIcon from "@mui/icons-material/Send";
import KeyIcon from "@mui/icons-material/Key";
import MicIcon from "@mui/icons-material/Mic";
import SelectModelDialog from "examples/ModelDialog";
import brandWhite from "assets/images/apple-icon.png";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import MessageBox from "./components/messageBox";
import { useMaterialUIController } from "context";
import ConfirmDialog from "examples/ConfirmDialog";

function ModelsTest() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;
  const location = useLocation();

  const [leftActive, setLeftActive] = useState(true);
  const [disableClick, setDisableClick] = useState(false);

  const [dialogs, setDialogs] = useState([]);
  const [dialogSelected, setDialogSelected] = useState(0);
  const [loadingDialogs, setLoadingDialogs] = useState(false);
  const handleClickDialog = (index) => {
    setDialogSelected(index);
    loadMessageFromServer(dialogs[index]);
  };
  const loadDialogs = async () => {
    if (loadingDialogs) return;
    setLoadingDialogs(true);
    setDisableClick(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/chat/findByUser`;
      let response = await axios.post(url, {
        userid: user.name,
      });
      if (response.status === 200) {
        let dialogsGot = response.data;
        let data = [];
        dialogsGot.forEach((curDia) => {
          let d = new Date(curDia.updated_time);
          let timeString = d.toLocaleString();
          data.push({
            id: curDia.id,
            title: curDia.name,
            time: timeString,
          });
        });
        if (data.length === 0) {
          handleAddDialog();
        }
        setDialogs(data);
      } else {
        setInfoLabel("加载对话列表");
        setInfoText("对话列表加载出错，请重试");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("加载对话列表");
      setInfoText("对话列表加载出错，请重试");
      setError(true);
    } finally {
      setLoadingDialogs(false);
      setDisableClick(false);
    }
  };
  const handleAddDialog = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.post(`${apiUrl}/chat`, {
        name: "新对话",
        userid: user.name,
      });
      if (response.status === 200) {
        loadDialogs();
      } else {
        setInfoLabel("创建新对话");
        setInfoText("新对话创建出错，请重试");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("创建新对话");
      setInfoText("新对话创建出错，请重试");
      setError(true);
    }
  };
  const [confirm, setConfirm] = useState(false);
  const [dialogToDelete, setDialogToDelete] = useState(0);
  const handleOpenConfirm = (index) => {
    setDialogToDelete(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };
  const handleDeleteDialog = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.delete(`${apiUrl}/chat/${dialogs[dialogToDelete].id}`, {
        headers: {
          Authorization: "Bearer sk-nQpY9Cw46m3bbiz2jFY1sFDc5EPbH5RwSMWbsmXSjjyEK1ic",
        },
      });
      if (response.status === 200) {
        loadDialogs();
      } else {
        setInfoLabel("删除对话记录");
        setInfoText("对话记录删除失败，请重试");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("删除对话记录");
      setInfoText("对话记录删除失败，请重试");
      setError(true);
    } finally {
      setConfirm(false);
    }
  };

  const [selectedModels, setSelectedModels] = useState(0);
  const [models, setModels] = useState([]);
  const [modelDialogOpen, setModelDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setModelDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setModelDialogOpen(false);
  };
  const handleSelectModel = (model) => {
    for (let i = 0; i < selectedModels; i++) {
      if (models[i].name === model.name) {
        setInfoLabel("选择模型");
        setInfoText("无法选择已经被选择的模型");
        setError(true);
        return;
      }
    }
    let newModel = {
      id: models.length,
      name: model.name,
    };
    if (modelToChange === 0 || modelToChange === 1) {
      let newModels = [];
      models.forEach((model) => {
        if (model.id < modelToChange) {
          newModels.push(model);
        } else if (model.id > modelToChange) {
          newModels.push(model);
        } else {
          newModels[modelToChange] = newModel;
          newModel.id = modelToChange;
        }
      });
      setModelToChange(-1);
      setModels(newModels);
    } else {
      setModels([...models, newModel]);
      setSelectedModels((prev) => (prev < 2 ? prev + 1 : prev));
    }
    if (chatText) {
      setSendAllowed(true);
    }
  };
  const handleDeleteModel = (id) => {
    let newModels = [];
    models.forEach((model) => {
      if (model.id < id) {
        newModels.push(model);
      }
      if (model.id > id) {
        model.id -= 1;
        newModels.push(model);
      }
    });
    if (!chatText || selectedModels === 1) {
      setSendAllowed(false);
    }
    setSelectedModels((prev) => prev - 1);
    setModels(newModels);
  };
  const [modelToChange, setModelToChange] = useState(-1);
  const handleChangeModel = (id) => {
    setModelToChange(id);
    setModelDialogOpen(true);
  };

  const [loading, setLoading] = useState([false, false]);
  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setError(false);

  const saveMessageToServer = async (chat_id, model, content, position) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.post(`${apiUrl}/message`, {
        chat_id: chat_id,
        model: model,
        content: content,
        position: position,
      });
      if (response.status === 200) {
        console.log("saved");
      } else {
        setInfoLabel("保存新消息");
        setInfoText("新消息保存失败");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("保存新消息");
      setInfoText("新消息保存失败");
      setError(true);
    }
  };
  const loadMessageFromServer = async (dialog) => {
    try {
      setModels([]);
      setSelectedModels(0);
      setChatHistory([[], []]);
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.post(`${apiUrl}/message/findByChat`, {
        chat_id: dialog.id,
      });
      if (response.status === 200) {
        let loadedModels = [];
        let loadedMessages = [];
        response.data.forEach((message) => {
          let d = new Date(message.updated_time);
          let timeString = d.toLocaleString();
          let r;
          if (message.model === "user") {
            r = "user";
          } else {
            r = "assistant";
          }
          let newChat = {
            content: message.content,
            role: r,
            time: timeString,
            model: message.model,
            last: false,
            position: message.position,
          };
          loadedMessages.push(newChat);
          if (message.model !== "user") {
            if (loadedModels.length === 2) {
              if (
                loadedModels[0].name !== message.model &&
                loadedModels[1].name !== message.model
              ) {
                loadedModels.pop(0);
                loadedModels.push({ name: message.model, id: 1 });
                loadedModels[0].id = 0;
              }
            } else if (loadedModels.length === 1) {
              if (loadedModels[0].name !== message.model) {
                loadedModels.push({ name: message.model, id: 1 });
              }
            } else {
              loadedModels.push({ name: message.model, id: 0 });
            }
          }
        });
        let newModels = [];
        let loadedLengthRec = loadedModels.length;
        if (loadedModels.length > 0) {
          newModels.push(loadedModels[0]);
          if (loadedModels.length > 1) {
            newModels.push(loadedModels[1]);
          }
        } else {
          if (location.state && location.state[0]) {
            newModels = [...models, location.state[0]];
            loadedLengthRec = 1;
          }
        }
        let newChatHistory = [[], []];
        loadedMessages.forEach((message, index) => {
          let pos = message.position;
          newChatHistory[pos] = [...newChatHistory[pos], message];
        });
        setModels(newModels);
        setSelectedModels(loadedLengthRec);
        setChatHistory(newChatHistory);
        if (chatText && loadedLengthRec > 0) {
          setSendAllowed(true);
        } else {
          setSendAllowed(false);
        }
      } else {
        setInfoLabel("加载历史消息");
        setInfoText("历史消息加载失败");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("加载历史消息");
      setInfoText("历史消息加载失败");
      setError(true);
    }
  };

  const [chatText, setChatText] = useState("");
  const handleChatTextChange = (event) => {
    const value = event.target.value;
    setChatText(value);
    if (value && selectedModels > 0) {
      setSendAllowed(true);
    } else {
      setSendAllowed(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      sendChat();
    }
  };
  const [chatHistory, setChatHistory] = useState([[], []]);
  const [sendAllowed, setSendAllowed] = useState(false);
  const sendChat = async () => {
    setSendAllowed(false);
    setDisableClick(true);
    let d = new Date();
    let timeString = d.toLocaleString();
    let newChat = {
      content: chatText,
      role: "user",
      time: timeString,
      last: false,
      model: "user",
    };
    let newChatHistory = [[], []];
    let l = [false, false];
    for (let i = 0; i < selectedModels; i++) {
      newChatHistory[i] = [...chatHistory[i], newChat];
      l[i] = true;
      saveMessageToServer(dialogs[dialogSelected].id, "user", chatText, i);
    }
    setLoading(l);
    if (dialogs[dialogSelected].title === "新对话") {
      let newName;
      if (chatText.length < 5) {
        newName = chatText;
      } else {
        newName = chatText.slice(0, 5);
      }
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        let response = await axios.put(`${apiUrl}/chat/${dialogs[dialogSelected].id}`, {
          name: newName,
          userid: user.name,
        });
        if (response.status === 200) {
          dialogs[dialogSelected].title = newName;
        } else {
          setInfoLabel("更新对话名");
          setInfoText("对话名更新失败");
          setError(true);
          setDisableClick(false);
          return;
        }
      } catch (error) {
        console.log(error);
        setInfoLabel("更新对话名");
        setInfoText("对话名更新失败");
        setError(true);
        setDisableClick(false);
        return;
      }
    }
    setChatHistory(newChatHistory);
    setChatText("");
    for (let i = 0; i < selectedModels; i++) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        let response = await axios.post(
          `${apiUrl}/v1/chat/completions`,
          {
            messages: newChatHistory[i],
            model: models[i].name,
            stream: false,
          },
          {
            headers: {
              Authorization: "Bearer sk-nQpY9Cw46m3bbiz2jFY1sFDc5EPbH5RwSMWbsmXSjjyEK1ic",
            },
          }
        );
        if (response.status === 200) {
          let d = new Date();
          let timeString = d.toLocaleString();
          let aiChat = {
            content: response.data.choices[0].message.content,
            role: response.data.choices[0].message.role,
            time: timeString,
            last: true,
            model: models[i].name,
          };
          newChatHistory[i] = [...newChatHistory[i], aiChat];
          setChatHistory([...newChatHistory]);
          saveMessageToServer(dialogs[dialogSelected].id, models[i].name, aiChat.content, i);
        } else {
          setInfoLabel("模型体验");
          setInfoText("模型对话出错");
          setError(true);
        }
      } catch (error) {
        console.log(error);
        setInfoLabel("模型体验");
        setInfoText("模型对话出错");
        setError(true);
      } finally {
        l[i] = false;
        setLoading([...l]);
        if (!l[0] && !l[1] && chatText) {
          setSendAllowed(true);
          setDisableClick(false);
        }
      }
    }
  };

  useEffect(() => {
    if (user) {
      loadDialogs();
    }
  }, [user]);

  useEffect(() => {
    if (dialogs && dialogs.length > 0) {
      handleClickDialog(0);
    }
  }, [dialogs]);

  const [pendingStateModel, setPendingStateModel] = useState(null);
  useEffect(() => {
    if (location.state && location.state[0]) {
      setPendingStateModel(location.state[0]);
      handleAddDialog();
    }
  }, [location.state]);

  useEffect(() => {
    if (models && models.length === 0 && pendingStateModel) {
      setModels((prevModels) => [...prevModels, pendingStateModel]);
      setSelectedModels(1);
      setPendingStateModel(null);
    }
  }, [models, pendingStateModel]);

  const leftSectionRef = useRef(null);
  const rightSectionRef = useRef(null);
  useEffect(() => {
    if (leftSectionRef.current) {
      leftSectionRef.current.scrollTop = leftSectionRef.current.scrollHeight;
    }
  }, [chatHistory[0]]);
  useEffect(() => {
    if (rightSectionRef.current) {
      rightSectionRef.current.scrollTop = rightSectionRef.current.scrollHeight;
    }
  }, [chatHistory[1]]);

  return (
    <DashboardLayout>
      <Box
        sx={{
          pointerEvents: disableClick ? "none" : "auto",
        }}
      >
        <Box display="flex" alignItems="flex-start" height="95vh" gap={2}>
          {!leftActive ? (
            <Box
              width="10vw"
              height="100%"
              display="flex"
              flexDirection="column"
              sx={{ overflow: "hidden" }}
            >
              <Button
                component="label"
                variant="contained"
                startIcon={<HistoryIcon />}
                onClick={() => setLeftActive(true)}
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#707070",
                  float: "right",
                  fontSize: 15,
                  position: "absolute",
                  left: 0,
                  top: "5%",
                }}
              >
                对话记录
              </Button>
            </Box>
          ) : (
            <Box
              width="10vw"
              height="100%"
              display="flex"
              flexDirection="column"
              sx={{ borderRadius: 1, bgcolor: "#ffffff", overflow: "hidden" }}
            >
              <Box sx={{ position: "relative", textAlign: "center", height: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 1rem",
                    mt: 2,
                  }}
                >
                  <HistoryIcon />
                  <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
                    对话记录
                  </Typography>
                  <IconButton onClick={() => setLeftActive(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Button
                  onClick={handleAddDialog}
                  variant="contained"
                  sx={{
                    backgroundColor: "#4b26df",
                    color: "#ffffff",
                    width: "80%",
                    mt: 2,
                    fontSize: 15,
                    fontWeight: "light",
                  }}
                >
                  创建对话
                </Button>
                <Box sx={{ overflowY: "auto", height: "90%", padding: "1rem" }}>
                  {dialogs.map((dialog, index) => (
                    <Box
                      key={dialog.id}
                      onClick={() => handleClickDialog(index)}
                      sx={{
                        marginBottom: "1rem",
                        padding: "1rem",
                        position: "relative",
                        border: "2px solid #cecece",
                        borderRadius: 2,
                        cursor: "pointer",
                        bgcolor: dialogSelected === index ? "#c3bcdf" : "#ffffff",
                        "&:hover": {
                          bgcolor: "#c9c9c9",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 16,
                          textAlign: "left",
                          paddingLeft: "1%",
                        }}
                      >
                        {dialog.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#868686",
                          fontSize: 14,
                          textAlign: "left",
                          paddingLeft: "1%",
                        }}
                      >
                        {dialog.time}
                      </Typography>
                      <IconButton
                        onClick={() => handleOpenConfirm(index)}
                        sx={{ position: "absolute", top: "17%", right: "1%" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
          <Box
            width="70vw"
            height="100%"
            display="flex"
            flexDirection="column"
            sx={{ overflow: "hidden" }}
          >
            <Box
              sx={{
                padding: "1rem",
                borderBottom: "1px solid #ddd",
                flexShrink: 0,
              }}
            >
              {selectedModels === 0 ? (
                <Box display="flex" alignItems="center">
                  <Typography flex="1">当前还未选择模型，请点击画面右侧按钮选择模型</Typography>
                </Box>
              ) : selectedModels === 1 ? (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderRadius: "20px",
                    padding: "0.5rem 1rem",
                    bgcolor: "#ffffff",
                  }}
                >
                  <Box
                    component="img"
                    src={brandWhite}
                    sx={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
                  />
                  <Typography sx={{ fontWeight: "bold", marginRight: "1rem" }}>
                    {models[0].name}
                  </Typography>
                  <Typography
                    flex="1"
                    sx={{ color: "#4b26df", cursor: "pointer", marginRight: "0.5rem" }}
                    onClick={() => handleChangeModel(0)}
                  >
                    更换模型
                  </Typography>
                  <IconButton>
                    <KeyIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteModel(0)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        borderRadius: "20px",
                        padding: "0.5rem 1rem",
                        bgcolor: "#ffffff",
                      }}
                    >
                      <Box
                        component="img"
                        src={brandWhite}
                        alt="模型图片"
                        sx={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
                      />
                      <Typography sx={{ fontWeight: "bold", marginRight: "1rem" }}>
                        {models[0].name}
                      </Typography>
                      <Typography
                        flex="1"
                        sx={{ color: "#4b26df", cursor: "pointer", marginRight: "0.5rem" }}
                        onClick={() => handleChangeModel(0)}
                      >
                        更换模型
                      </Typography>
                      <IconButton>
                        <KeyIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteModel(0)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        borderRadius: "20px",
                        padding: "0.5rem 1rem",
                        bgcolor: "#ffffff",
                      }}
                    >
                      <Box
                        component="img"
                        src={brandWhite}
                        alt="模型图片"
                        sx={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
                      />
                      <Typography sx={{ fontWeight: "bold", marginRight: "1rem" }}>
                        {models[1].name}
                      </Typography>
                      <Typography
                        flex="1"
                        sx={{ color: "#4b26df", cursor: "pointer", marginRight: "0.5rem" }}
                        onClick={() => handleChangeModel(1)}
                      >
                        更换模型
                      </Typography>
                      <IconButton>
                        <KeyIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteModel(1)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box sx={{ flex: "1 1 auto", display: "flex", gap: 2, overflow: "hidden" }}>
              {selectedModels >= 1 && (
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  ref={leftSectionRef}
                >
                  {chatHistory[0].map((message, index) => (
                    <MessageBox
                      key={index}
                      message={message}
                      last={message.last}
                      scrollContainerRef={leftSectionRef}
                    />
                  ))}
                  {loading[0] && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1rem",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              )}
              {selectedModels === 2 && (
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  ref={rightSectionRef}
                >
                  {chatHistory[1].map((message, index) => (
                    <MessageBox
                      key={index}
                      message={message}
                      last={message.last}
                      scrollContainerRef={rightSectionRef}
                    />
                  ))}
                  {loading[1] && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1rem",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box sx={{ padding: "1rem", flexShrink: 0 }}>
              <Box display="flex" alignItems="center" gap={1} sx={{ mb: 1 }}>
                <IconButton
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #cecece",
                    padding: "0.5rem",
                    bgcolor: "#ffffff",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                  }}
                >
                  <MicIcon />
                </IconButton>
                <IconButton
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #cecece",
                    padding: "0.5rem",
                    bgcolor: "#ffffff",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                  }}
                >
                  <ImageIcon />
                </IconButton>
                <IconButton
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #cecece",
                    padding: "0.5rem",
                    bgcolor: "#ffffff",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                  }}
                >
                  <FileOpenIcon />
                </IconButton>
                <Box display="flex" alignItems="center" justifyContent="center" gap={1} ml={1}>
                  <InfoIcon sx={{ fontSize: "small" }} />
                  <Typography sx={{ fontSize: 14, color: "#858585", mt: 0.5 }}>
                    体验模型将会消耗tokens，费用以实际发生为准
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" position="relative" alignItems="center" sx={{ mb: 1 }}>
                <TextField
                  variant="outlined"
                  placeholder="请输入您的问题，最长1000字"
                  multiline
                  fullWidth
                  value={chatText}
                  onChange={handleChatTextChange}
                  onKeyDown={handleKeyPress}
                  inputProps={{ maxLength: 1000 }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "0.5rem",
                      paddingRight: "50px",
                      borderRadius: "20px",
                      bgcolor: "#ffffff",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                />
                <IconButton
                  disabled={!sendAllowed}
                  sx={{
                    position: "absolute",
                    right: "1rem",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#ffffff",
                    bgcolor: "#696969",
                    borderRadius: 2,
                    padding: "0.5rem",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                  }}
                  onClick={sendChat}
                >
                  <SendIcon />
                </IconButton>
              </Box>
              <Typography textAlign="center" sx={{ fontSize: 14, color: "#858585" }}>
                对话中的所有回答内容均由人工智能模型生成，不代表公司态度或观点
              </Typography>
            </Box>
          </Box>
          <Box
            width="2vw"
            height="60%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={selectedModels < 2 ? handleOpenDialog : undefined}
            sx={{
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              bgcolor: "#ffffff",
              overflow: "hidden",
              cursor: "pointer",
              position: "absolute",
              top: "20%",
              right: 0,
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
              padding: "1rem",
            }}
          >
            <Typography variant="h6">+</Typography>
            <Typography variant="body2" mt={1}>
              增加模型
            </Typography>
            <Typography variant="body2" mt={1}>
              ({selectedModels}/2)
            </Typography>
          </Box>
        </Box>
      </Box>
      <SelectModelDialog
        open={modelDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleSelectModel}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6">
              {infoLabel}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {infoText}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title="删除对话记录"
        info="是否确认删除当前选择的对话记录？删除操作不可恢复"
        confirm={handleDeleteDialog}
      />
    </DashboardLayout>
  );
}

export default ModelsTest;
