/**

*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Button, Box, Typography } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useMaterialUIController } from "context";
import ApiKeyDialog from "examples/APIDialog";
import brandWhite from "assets/images/apple-icon.png";
import CreateAppDialog from "examples/AppDialog";
import ConfirmDialog from "examples/ConfirmDialog";
import MessageModal from "examples/MessageModal";
import EditAppDialog from "examples/AppDialog/EditAppDialog";
import axios from "axios";
import ErrorModal from "examples/ErrorModal";

function AppsOwn() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;

  const [loading, setLoading] = useState(false);

  const [apiDialogOpen, setApiDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setApiDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setApiDialogOpen(false);
  };

  const [selectedApp, setSelectedApp] = useState(0);

  const [create, setCreate] = useState(false);
  const createApp = (name, info) => {
    getApps();
    setCreate(false);
  };
  const handleOpenCreate = () => {
    setCreate(true);
  };
  const handleCloseCreate = () => {
    setCreate(false);
  };

  const [edit, setEdit] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleOpenEditModal = () => {
    setEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };
  const editApp = async (app) => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.put(`${apiUrl}/app/${apps[selectedApp].id}`, {
        headers: {
          Authorization: "Bearer sk-nQpY9Cw46m3bbiz2jFY1sFDc5EPbH5RwSMWbsmXSjjyEK1ic",
        },
        name: app.name,
        description: app.description,
        userid: app.userid,
      });
      if (response.status === 200) {
        getApps();
      } else {
        handleOpenEditModal();
      }
    } catch (error) {
      console.log(error);
      handleOpenEditModal();
    } finally {
      setLoading(false);
      setEdit(false);
    }
  };
  const handleOpenEdit = (index) => {
    setSelectedApp(index);
    setEdit(true);
  };
  const handleCloseEdit = () => {
    setEdit(false);
  };

  const [confirm, setConfirm] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDelete = () => {
    setDeleteModalOpen(true);
  };
  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };
  const deleteApp = async () => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.delete(`${apiUrl}/app/${apps[selectedApp].id}`, {
        headers: {
          Authorization: "Bearer sk-nQpY9Cw46m3bbiz2jFY1sFDc5EPbH5RwSMWbsmXSjjyEK1ic",
        },
      });
      if (response.status === 200) {
        handleOpenModal();
        getApps();
      } else {
        handleOpenDelete();
      }
    } catch (error) {
      console.log(error);
      handleOpenDelete();
    } finally {
      setLoading(false);
      setConfirm(false);
    }
  };
  const handleOpenConfirm = (index) => {
    setSelectedApp(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleOpenErrorModal = () => {
    setErrorModalOpen(true);
  };
  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };
  const [apps, setApps] = useState([]);
  const getApps = async () => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/app/findByUser`;
      let response = await axios.post(url, {
        userid: user.name,
      });
      if (response.status === 200) {
        let apps = response.data;
        setApps(apps);
      } else {
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.log(error);
      setErrorModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const handleAppDetail = (app) => {
    navigate("/apps_own/app_detail", { state: [app] });
  };

  useEffect(() => {
    if (user) {
      getApps();
    }
  }, [user]);

  return (
    <DashboardLayout>
      <Box height="95vh">
        <Box sx={{ bgcolor: "#ffffff" }}>
          <Box display="flex" alignItems="center" pt={4}>
            <Typography px={4} component="h6" variant="h4" fontWeight="regular" color={"#4b26df"}>
              我的应用
            </Typography>
            <KeyIcon fontSize="small" pt={4}>
              KeyIcon
            </KeyIcon>
            <Typography
              px={1}
              mt={0.5}
              component="h6"
              variant="subtitle2"
              fontWeight="light"
              color={"#4b26df"}
              sx={{ cursor: "pointer" }}
              onClick={handleOpenDialog}
            >
              查看我的API KEY
            </Typography>
            <Box flex={1} display="flex" justifyContent="flex-end" pr={4}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#4b26df",
                  color: "#ffffff",
                  float: "right",
                  mt: 1,
                }}
                onClick={handleOpenCreate}
              >
                创建应用
              </Button>
            </Box>
          </Box>
          <Typography
            pt={1}
            px={4}
            pb={4}
            component="h6"
            variant="caption"
            fontWeight="light"
            color={"#a0a0a0"}
          >
            用户的应用管理中心，用于集中管理所有应用
          </Typography>
        </Box>
        <Box
          flex={1}
          height="90%"
          overflow="auto"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap={2}
          alignContent="start"
          mt={2}
        >
          {loading && <CircularProgress sx={{ mt: 2 }} />}
          {apps.map((object, index) => (
            <Box
              key={index}
              maxWidth="100%"
              height="auto"
              position="relative"
              sx={{
                borderRadius: 5,
                bgcolor: "#ffffff",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                "&:hover": {
                  bgcolor: "#ececec",
                },
              }}
            >
              <Box display="flex" alignItems="center">
                <Box
                  component="img"
                  sx={{
                    height: 40,
                    width: 40,
                    mr: 2,
                  }}
                  src={brandWhite}
                />
                <Typography component="h6" variant="h5" fontWeight="normal" color={"#000000"}>
                  {object.name}
                </Typography>
              </Box>
              <Box mt={1} mb={2} ml={7}>
                <Typography
                  component="h6"
                  variant="subtitle2"
                  fontWeight="light"
                  color={"#838383"}
                  sx={{
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {object.description}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography
                  component="h6"
                  variant="subtitle2"
                  fontWeight="light"
                  color={"#808080"}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#4b26df",
                    },
                    mr: 2,
                  }}
                  onClick={() => handleOpenConfirm(index)}
                >
                  删除
                </Typography>
                <Typography
                  component="h6"
                  variant="subtitle2"
                  fontWeight="light"
                  color={"#808080"}
                  mr={2}
                >
                  |
                </Typography>
                <Typography
                  component="h6"
                  variant="subtitle2"
                  fontWeight="light"
                  color={"#808080"}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#4b26df",
                    },
                    mr: 2,
                  }}
                  onClick={() => handleOpenEdit(index)}
                >
                  编辑
                </Typography>
                {/*<Typography
                  component="h6"
                  variant="subtitle2"
                  fontWeight="light"
                  color={"#808080"}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#4b26df",
                    },
                    mr: 2,
                  }}
                  onClick={() => handleAppDetail(object)}
                >
                  查看API
                </Typography>*/}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <ApiKeyDialog open={apiDialogOpen} onClose={handleCloseDialog} />
      <CreateAppDialog open={create} onClose={handleCloseCreate} onSubmit={createApp} />
      <EditAppDialog
        open={edit}
        onClose={handleCloseEdit}
        onSubmit={editApp}
        app={apps[selectedApp]}
      />
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title="删除App"
        info="是否确认删除当前选择的App? App被删除后无法恢复，且会导致与该App关联的Api-Key全部被删除"
        confirm={deleteApp}
      />
      <MessageModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={`已经成功删除选择的App`}
        time={1000}
      />
      <ErrorModal
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        message={`我的应用加载出错，请重试`}
        time={1000}
      />
      <ErrorModal
        open={deleteModalOpen}
        onClose={handleCloseDelete}
        message={`删除应用失败，请重试`}
        time={1000}
      />
      <ErrorModal
        open={editModalOpen}
        onClose={handleCloseEditModal}
        message={`编辑应用失败，请重试`}
        time={1000}
      />
    </DashboardLayout>
  );
}

export default AppsOwn;
