/**

*/
import { useEffect, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";
import KeyIcon from "@mui/icons-material/Key";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import { useMaterialUIController, setMiniSidenav } from "context";
import { setUser } from "context";
import ApiKeyDialog from "examples/APIDialog";

function Sidenav({ color, brand, brandName, brandInfo, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, user } = controller;
  const location = useLocation();
  let collapseName = location.pathname.replace("/", "");
  collapseName = collapseName.split("/")[0];

  const [menu, setMenu] = useState(null);
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const logout = () => {
    setUser(dispatch, undefined);
    localStorage.clear();
    navigate("/authentication/sign-in");
  };

  const renderMenu = (
    <Menu
      id="auth-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={logout}>退出登录</MenuItem>
    </Menu>
  );

  let textColor = "dark";

  const [apiDialogOpen, setApiDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setApiDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setApiDialogOpen(false);
  };

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const renderRoutes = routes.map(({ type, name, icon, title, key, href, route, mustShow }) => {
    let returnValue;
    if (type === "collapse" && mustShow) {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title" && mustShow) {
      returnValue = (
        <Typography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </Typography>
      );
    } else if (type === "divider" && mustShow) {
      returnValue = <Divider key={key} />;
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <Box pt={3} pb={1} px={4} textAlign="center">
        <Box
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <Typography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </Typography>
        </Box>
        <Box component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <Box component="img" src={brand} alt="Brand" width="3rem" />}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={(theme) => ({
              ...sidenavLogoLabel(theme, { miniSidenav }),
              width: !brandInfo && "100%",
              textAlign: "center",
            })}
          >
            <Typography px={2} component="h6" variant="h4" fontWeight="regular" color={textColor}>
              {brandName}
            </Typography>
            <Typography px={1} component="h6" variant="h6" fontWeight="regular" color={textColor}>
              {brandInfo}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <List>{renderRoutes}</List>
      <Box p={2} mt="auto">
        <Divider />
        <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={openMenu}>
          <AccessibleForwardIcon fontSize="small">AccessibleForwardIcon</AccessibleForwardIcon>
          <Typography px={2} component="h6" variant="h5" fontWeight="light" color={textColor}>
            {user ? user.name : "用户名字段"}
          </Typography>
        </Box>
        {renderMenu}
        <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }} pt={1}>
          <KeyIcon fontSize="small">KeyIcon</KeyIcon>
          <Typography
            px={2}
            component="h6"
            variant="body2"
            fontWeight="light"
            color={textColor}
            onClick={handleOpenDialog}
          >
            查看我的API KEY
          </Typography>
        </Box>
      </Box>
      <ApiKeyDialog open={apiDialogOpen} onClose={handleCloseDialog} />
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  brandInfo: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
