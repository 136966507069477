/**

*/

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import KeyIcon from "@mui/icons-material/Key";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ApiKeyDialog from "examples/APIDialog";
import brandWhite from "assets/images/apple-icon.png";
import ConfirmDialog from "examples/ConfirmDialog";
import MessageModal from "examples/MessageModal";

function AppDetail() {
  const [app, setApp] = useState({});
  const [apis, setApis] = useState([]);
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state[0]) {
      setApp(location.state[0]);
      setApis(location.state[0].apis);
    } else {
      navigate("/apps_own");
    }
  }, [location.state]);

  const [apiDialogOpen, setApiDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setApiDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setApiDialogOpen(false);
  };

  const navigate = useNavigate();
  const handleBackApps = () => {
    navigate("/apps_own");
  };
  const handleApiSample = (api) => {
    navigate("/apps_own/app_detail/api_sample", { state: [app, api] });
  };
  const handleNewApi = () => {
    navigate("/apps_own/app_detail/new_api", { state: [app] });
  };

  const [selectedApi, setSelectedApi] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const deleteApi = () => {
    handleOpenModal();
    setApis((prevData) => prevData.filter((_, i) => i !== selectedApi));
    setConfirm(false);
  };
  const handleOpenConfirm = (index) => {
    setSelectedApi(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <DashboardLayout>
      <Box height="95vh">
        <Box sx={{ bgcolor: "#ffffff" }}>
          <Box display="flex" alignItems="center" pt={4}>
            <Typography
              pl={4}
              component="h6"
              variant="h4"
              fontWeight="regular"
              color={"#4b26df"}
              sx={{ cursor: "pointer" }}
              onClick={handleBackApps}
            >
              我的应用
            </Typography>
            <Typography pl={2} component="h6" variant="h4" fontWeight="regular" color={"#4b26df"}>
              / {app.name}
            </Typography>
            <KeyIcon fontSize="small" sx={{ ml: 3 }}>
              KeyIcon
            </KeyIcon>
            <Typography
              pl={1}
              mt={0.5}
              component="h6"
              variant="subtitle2"
              fontWeight="light"
              color={"#4b26df"}
              sx={{ cursor: "pointer" }}
              onClick={handleOpenDialog}
            >
              查看我的API KEY
            </Typography>
            <Box flex={1} display="flex" justifyContent="flex-end" pr={4}>
              <Box
                component="img"
                sx={{
                  height: 60,
                  width: 60,
                  mr: 4,
                }}
                src={brandWhite}
              />
            </Box>
          </Box>
          <Typography
            pt={2}
            px={4}
            pb={2}
            component="h6"
            variant="caption"
            fontWeight="regular"
            color={"#000000"}
          >
            应用简介
          </Typography>
          <Typography
            px={4}
            pb={4}
            component="h6"
            variant="caption"
            fontWeight="light"
            color={"#a0a0a0"}
          >
            {app.description}
          </Typography>
        </Box>
        <Box
          mt={2}
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          height="85%"
          sx={{
            borderRadius: 1,
            bgcolor: "#ffffff",
            overflow: "auto",
          }}
        >
          <Box pt={2} px={4} pb={2}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#4b26df",
                color: "#ffffff",
                mt: 1,
              }}
              onClick={handleNewApi}
            >
              + 创建API
            </Button>
          </Box>
          <Box width="96%" mx="auto">
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead sx={{ display: "table-header-group" }}>
                  <TableRow>
                    <TableCell>API</TableCell>
                    <TableCell>API ID</TableCell>
                    <TableCell>模型基座</TableCell>
                    <TableCell>状态</TableCell>
                    <TableCell>操作</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {apis.map((api, index) => (
                    <TableRow key={index}>
                      <TableCell>{api.name}</TableCell>
                      <TableCell>{api.apiId}</TableCell>
                      <TableCell>{api.modelBase}</TableCell>
                      <TableCell>{api.status}</TableCell>
                      <TableCell>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          fontWeight="light"
                          color={"#4b26df"}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "#808080",
                            },
                            mr: 2,
                          }}
                          onClick={() => handleApiSample(api)}
                        >
                          调用
                        </Typography>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          fontWeight="light"
                          color={"#808080"}
                          mr={2}
                        >
                          |
                        </Typography>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          fontWeight="light"
                          color={"#4b26df"}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "#808080",
                            },
                          }}
                          onClick={() => handleOpenConfirm(index)}
                        >
                          删除
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
      <ApiKeyDialog open={apiDialogOpen} onClose={handleCloseDialog} />
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title="删除Api"
        info="是否确认删除当前选择的Api? Api删除后无法恢复"
        confirm={deleteApi}
      />
      <MessageModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={`已经成功删除选择的Api`}
        time={1000}
      />
    </DashboardLayout>
  );
}

export default AppDetail;
