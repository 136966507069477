import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import axios from "axios";
import ErrorModal from "examples/ErrorModal";
import { useMaterialUIController } from "context";

const CreateApiKeyDialog = ({ open, onClose, onSubmit }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;

  const [selectedApp, setSelectedApp] = useState({});
  const [description, setDescription] = useState("");

  const handleAppChange = (event) => {
    const appId = event.target.value;
    const app = apps.find((app) => app.id === appId);
    setSelectedApp(app);
  };

  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= 10) {
      setDescription(event.target.value);
    }
  };

  const [newApiFail, setNewApiFail] = useState(false);
  const handleOpenNewApiFail = () => {
    setNewApiFail(true);
  };
  const handleCloseNewApiFail = () => {
    setNewApiFail(false);
  };
  const handleSubmit = async () => {
    if (selectedApp && description) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        let response = await axios.post(`${apiUrl}/apiKey`, {
          app_id: selectedApp.id,
          description: description,
        });
        if (response.status === 200) {
          onSubmit(selectedApp.name, description);
          setSelectedApp({});
          setDescription("");
        } else {
          handleOpenNewApiFail();
        }
      } catch (error) {
        console.log(error);
        handleOpenNewApiFail();
      }
    }
  };

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleOpenErrorModal = () => {
    setErrorModalOpen(true);
  };
  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };
  const [apps, setApps] = useState([]);
  const getApps = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/app/findAll`;
      let response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        let apps = response.data;
        apps = apps.filter((app, index) => app.userid === user.name);
        setApps(apps);
        if (apps.length > 0) {
          setSelectedApp(apps[0]);
        }
      } else {
        handleOpenErrorModal();
      }
    } catch (error) {
      console.log(error);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    if (open) {
      getApps();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>创建新的API-KEY</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="app-select-label">归属应用</InputLabel>
          <Select
            labelId="app-select-label"
            value={selectedApp.id || ""}
            onChange={handleAppChange}
            sx={{ height: "5vh" }}
            input={<OutlinedInput label="归属应用" />}
          >
            {apps.map((app) => (
              <MenuItem key={app.id} value={app.id}>
                {app.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="描述"
          multiline
          fullWidth
          margin="normal"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="请输入描述(最长10个字)"
          sx={{ flexGrow: 1, height: "5vh" }}
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            float: "right",
            borderColor: "#6e6e6e",
          }}
        >
          取消
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#4b26df",
            color: "#ffffff",
            float: "right",
          }}
        >
          确定
        </Button>
      </DialogActions>
      <ErrorModal
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        message={`归属应用加载出错，请重试`}
        time={1000}
      />
      <ErrorModal
        open={newApiFail}
        onClose={handleCloseNewApiFail}
        message={`创建新的Api-Key失败，请重试`}
        time={1000}
      />
    </Dialog>
  );
};

CreateApiKeyDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
};

CreateApiKeyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateApiKeyDialog;
