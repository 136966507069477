import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import brandWhite from "assets/images/apple-icon.png";

const EditAppDialog = ({ open, onClose, onSubmit, app }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleNameChange = (event) => {
    if (event.target.value.length <= 10) {
      setName(event.target.value);
    }
  };

  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= 100) {
      setDescription(event.target.value);
    }
  };

  const handleSubmit = () => {
    if (name && description) {
      app.name = name;
      app.description = description;
      onSubmit(app);
      setName("");
      setDescription("");
    }
  };

  useEffect(() => {
    if (app) {
      setName(app.name);
      setDescription(app.description);
    }
  }, [app]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>编辑API-KEY信息</DialogTitle>
      <DialogContent>
        <TextField
          label="应用名称"
          fullWidth
          margin="normal"
          value={name}
          onChange={handleNameChange}
          placeholder="请输入名称"
          sx={{ flexGrow: 1 }}
          size="small"
        />
        <TextField
          label="描述"
          multiline
          fullWidth
          margin="normal"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="请输入描述"
          sx={{ flexGrow: 1 }}
          size="small"
        />
        <Box display="flex" alignItems="center" pt={2}>
          <Typography px={1} component="h6" variant="h6" fontWeight="regular" color={"#000000"}>
            应用LOGO:
          </Typography>
          <Box
            component="img"
            sx={{
              height: 40,
              width: 40,
              mr: 2,
            }}
            src={brandWhite}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            float: "right",
            borderColor: "#6e6e6e",
          }}
        >
          取消
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#4b26df",
            color: "#ffffff",
            float: "right",
          }}
        >
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditAppDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
  app: {},
};

EditAppDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
};

export default EditAppDialog;
