/**

*/
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress, Button, Box, Typography } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { useMaterialUIController, setUser, setRegis } from "context";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/auth_background.png";
import UserAgreement from "../components/UserAgreement";
import PrivacyPolicy from "../components/PrivacyPolicy";
import ReportIssue from "../components/Report";

function Basic() {
  const url = process.env.REACT_APP_PASS_URL;

  const [controller, dispatch] = useMaterialUIController();
  const { user, appid, regis } = controller;

  const [userAgreementOpen, setUserAgreementOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const handleUserAgreementOpen = () => {
    setUserAgreementOpen(true);
  };
  const handleUserAgreementClose = () => {
    setUserAgreementOpen(false);
  };
  const handlePrivacyPolicyOpen = () => {
    setPrivacyPolicyOpen(true);
  };
  const handlePrivacyPolicyClose = () => {
    setPrivacyPolicyOpen(false);
  };
  const handleReportOpen = () => {
    setReportOpen(true);
  };
  const handleReportClose = () => {
    setReportOpen(false);
  };

  const labelName = {
    0: "请输入手机号",
    1: "手机号有效",
    2: "手机号无效",
  };

  const phoneAreas = [
    { countrycode: "CN", value: "+86", text: "China (+86)" },
    { countrycode: "US", value: "+1", text: "USA (+1)" },
    { countrycode: "JP", value: "+81", text: "Japan (+81)" },
  ];

  const [selectedArea, setSelectedArea] = useState(0);
  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(0);
  const [sms, setSms] = useState("");

  const handleAreaChange = (event) => {
    setSelectedArea(parseInt(event.target.value));
    let area = phoneAreas[parseInt(event.target.value)].countrycode;
    let phoneNum = phone;
    validatePhone(area, phoneNum);
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    setPhone(numericValue);
    let phoneNum = numericValue;
    let area = phoneAreas[selectedArea].countrycode;
    validatePhone(area, phoneNum);
  };

  const handleSmsChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    setSms(numericValue);
  };

  const validatePhone = (area, phoneNum) => {
    let isValid = 0;
    if (area === "CN") {
      const cnsRegex = /^1[0-9]{10}$/;
      if (phoneNum.match(cnsRegex)) {
        isValid = 1;
      } else {
        isValid = 2;
      }
    } else {
      if (isValidPhoneNumber(phoneNum, area)) {
        isValid = 1;
      } else {
        isValid = 2;
      }
    }
    if (phoneNum.length === 0) {
      isValid = 0;
    }
    setPhoneValid(isValid);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const [loginVId, setLoginVId] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [smsSended, setSmsSended] = useState(false);
  const [smsCooldown, setCoolDown] = useState(0);

  const handleClose = () => setError(false);

  const getSmsCode = async () => {
    if (
      localStorage.getItem(phoneAreas[selectedArea].value + phone + "Login") &&
      Date.parse(localStorage.getItem(phoneAreas[selectedArea].value + phone + "Login")) >
        new Date()
    ) {
      setInfoLabel("错误");
      setInfoText("当前手机号还在60秒冷却内");
      setError(true);
      return;
    } else {
      localStorage.clear();
    }
    setLoading(true);
    try {
      let response = await axios.post(
        `${url}/auth/api/check-phone`,
        {
          phone: phoneAreas[selectedArea].value + phone,
          appid: appid,
        },
        { headers: { "Content-Type": "text/plain" } }
      );
      if (!response.data.registered) {
        setInfoLabel("获取短信结果");
        setInfoText("您的手机号还未注册，请前往注册");
        setError(true);
      } else {
        response = await axios.post(
          `${url}/auth/api/send-sms`,
          {
            phone: phoneAreas[selectedArea].value + phone,
            appid: appid,
          },
          { headers: { "Content-Type": "text/plain" } }
        );
        setLoginVId(response.data.verification_id);
        setSmsSended(true);
        setCoolDown(60);
        let currentTime = new Date();
        let cooldownTime = new Date(currentTime.getTime() + 60000);
        localStorage.setItem(phoneAreas[selectedArea].value + phone + "Login", cooldownTime);
        let timer = setInterval(() => {
          setCoolDown((prevCooldown) => {
            if (prevCooldown === 1) {
              clearInterval(timer);
              setSmsSended(false);
              return 0;
            } else {
              return prevCooldown - 1;
            }
          });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("获取短信结果");
      setInfoText("手机号校验出错");
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  let getSmsText = "";
  if (smsSended) {
    getSmsText = `${smsCooldown}秒后重新获取`;
  } else {
    getSmsText = "获取验证码";
  }

  const navigate = useNavigate();

  const login = async () => {
    if (!phoneValid) {
      setInfoLabel("登录结果");
      setInfoText("请填写有效的手机号");
      setError(true);
      return;
    }
    setLoading(true);
    try {
      let response = await axios.post(
        `${url}/auth/api/verify-sms`,
        {
          phone: phoneAreas[selectedArea].value + phone,
          appid: appid,
          code: sms,
          verification_id: loginVId,
        },
        { headers: { "Content-Type": "text/plain" } }
      );
      if (response.status === 200) {
        response = await axios.post(
          `${url}/auth/api/login`,
          {
            identifier: phoneAreas[selectedArea].value + phone,
            id_type: "phone",
            login_type: "sms",
            appid: appid,
            code: sms,
            password: sms,
            verification_id: loginVId,
          },
          { headers: { "Content-Type": "text/plain" } }
        );
        if (response.status === 200 || response.status === 201) {
          setInfoLabel("登录结果");
          setInfoText("登录成功");
          setError(true);
          localStorage.setItem(
            "maasAuthData",
            JSON.stringify({
              token: response.data.token,
              phone: phoneAreas[selectedArea].value + phone,
            })
          );
          let loggedUser = {
            name: phoneAreas[selectedArea].value + phone,
          };
          setUser(dispatch, loggedUser);
          setTimeout(() => {
            setError(false);
            navigate("/");
          }, 1000);
        } else {
          setInfoLabel("登录结果");
          setInfoText("登录失败，请重试");
          setError(true);
        }
      } else {
        setInfoLabel("登录结果");
        setInfoText("验证码错误");
        setError(true);
      }
    } catch (error) {
      setInfoLabel("登录结果");
      setInfoText("登录失败，请重试");
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const autoLogin = async () => {
    if (!localStorage.getItem("maasAuthData")) {
      console.log("No token found, please log in");
      return;
    }
    const authData = JSON.parse(localStorage.getItem("maasAuthData"));
    setLoading(true);
    try {
      let response = await axios.get(`${url}/auth/api/auto-login`, {
        headers: { Authorization: `${authData.token}` },
      });
      if (response.status === 200) {
        setInfoLabel("登录结果");
        setInfoText("自动登录成功");
        setError(true);
        let loggedUser = {
          name: authData.phone,
        };
        setUser(dispatch, loggedUser);
        setTimeout(() => {
          setError(false);
          navigate("/");
        }, 1000);
      } else {
        console.log("自动登录未成功");
        localStorage.clear();
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("登录结果");
      setInfoText("自动登录时出错");
      setError(true);
      localStorage.clear();
    } finally {
      setLoading(false);
    }
  };

  const loadRegis = async () => {
    try {
      let url = `${process.env.REACT_APP_PASS_URL}/auth/api/app/${process.env.REACT_APP_APP_ID}`;
      let response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        setRegis(dispatch, response.data.registration_switch);
      } else {
        setInfoLabel("载入注册开关");
        setInfoText("注册开关载入失败，请刷新页面重试");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setInfoLabel("载入注册开关");
      setInfoText("注册开关载入失败，请刷新页面重试");
      setError(true);
    }
  };

  useEffect(() => {
    loadRegis();
    autoLogin();
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <Box mx={2} mt={2} p={2} mb={1} textAlign="center">
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Button
                sx={{
                  fontSize: 16,
                  color: "#4b26df",
                  flex: 1,
                  borderRadius: 0,
                  borderBottom: "2px solid",
                  borderColor: "#4b26df",
                }}
              >
                手机号登录
              </Button>
            </Grid>
            <Grid item>
              <Box mx={2}></Box>
            </Grid>
            <Grid item>
              {regis && (
                <Button
                  component={NavLink}
                  to="/authentication/sign-up"
                  sx={{
                    fontSize: 16,
                    color: "#000000",
                  }}
                >
                  注册
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box pt={2} pb={2} px={3}>
          <Box component="form" role="form" id="form">
            <Box mb={2} sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="signin_area"
                select
                label="区号"
                value={selectedArea}
                onChange={handleAreaChange}
                variant="standard"
              >
                {phoneAreas.map((option, index) => (
                  <MenuItem key={index} value={index}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                error={phoneValid === 2}
                id="signin_phone"
                label={labelName[phoneValid]}
                variant="standard"
                type="tel"
                sx={{ flexGrow: 1 }}
                size="small"
                inputProps={{
                  pattern: "\\d*",
                  inputMode: "numeric",
                  maxLength: 11,
                }}
                value={phone}
                onChange={handlePhoneChange}
              />
            </Box>
            <Box mb={2} sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                id="signin_sms"
                label={"请输入验证码"}
                variant="standard"
                type="tel"
                sx={{ flexGrow: 1 }}
                size="small"
                inputProps={{
                  pattern: "\\d*",
                  inputMode: "numeric",
                  maxLength: 6,
                }}
                value={sms}
                onChange={handleSmsChange}
              />
              <Button
                variant="text"
                color="secondary"
                disabled={phoneValid !== 1 || smsSended}
                onClick={getSmsCode}
                sx={{
                  borderRadius: 0,
                  borderBottom: "1px solid",
                  borderColor: "#cccccc",
                }}
              >
                {getSmsText}
              </Button>
            </Box>
            <Box mt={4} mb={1}>
              <Button
                fullWidth
                onClick={login}
                sx={{
                  fontSize: 14,
                  color: "#ffffff",
                  backgroundColor: "#4b26df",
                  "&:hover": {
                    color: "#ffffff",
                    bgcolor: "#8037df",
                  },
                }}
              >
                登录
              </Button>
            </Box>
            {loading && <CircularProgress sx={{ mt: 2 }} />}
            <Box mt={3} mb={1} textAlign="center">
              <Typography variant="caption" color="text">
                登录视为您已同意
                <Typography
                  variant="caption"
                  component="span"
                  color="primary"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={handleUserAgreementOpen}
                >
                  用户协议
                </Typography>
                、
                <Typography
                  variant="caption"
                  component="span"
                  color="primary"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={handlePrivacyPolicyOpen}
                >
                  隐私协议
                </Typography>
              </Typography>
            </Box>
            <Box mt={2} mb={1} textAlign="center">
              <Typography
                variant="caption"
                component="span"
                color="#adadad"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={handleReportOpen}
              >
                投诉与反馈
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
      <UserAgreement open={userAgreementOpen} onClose={handleUserAgreementClose} />
      <PrivacyPolicy open={privacyPolicyOpen} onClose={handlePrivacyPolicyClose} />
      <ReportIssue open={reportOpen} onClose={handleReportClose} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6">
              {infoLabel}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {infoText}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </BasicLayout>
  );
}

export default Basic;
