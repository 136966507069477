/**

*/

import { Box } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

function AppsView() {
  return (
    <DashboardLayout>
      <Box py={1}>暂无内容</Box>
      <Footer />
    </DashboardLayout>
  );
}

export default AppsView;
