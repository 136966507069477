import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import brandWhite from "assets/images/apple-icon.png";
import MessageModal from "examples/MessageModal";
import { useMaterialUIController } from "context";
import ErrorModal from "examples/ErrorModal";

const CreateAppDialog = ({ open, onClose, onSubmit }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNameChange = (event) => {
    if (event.target.value.length <= 10) {
      setName(event.target.value);
    }
  };

  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= 100) {
      setDescription(event.target.value);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleSubmit = async () => {
    if (name && description) {
      setLoading(true);
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        let response = await axios.post(`${apiUrl}/app`, {
          name: name,
          description: description,
          userid: user.name,
        });
        if (response.status === 200) {
          onSubmit(name, description);
          setName("");
          setDescription("");
        } else {
          setModalOpen(true);
        }
      } catch (error) {
        console.log(error);
        setModalOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>创建新的API-KEY</DialogTitle>
      <DialogContent>
        <TextField
          label="应用名称"
          fullWidth
          margin="normal"
          value={name}
          onChange={handleNameChange}
          placeholder="请输入名称"
          sx={{ flexGrow: 1 }}
          size="small"
        />
        <TextField
          label="描述"
          multiline
          fullWidth
          margin="normal"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="请输入描述"
          sx={{ flexGrow: 1 }}
          size="small"
        />
        <Box display="flex" alignItems="center" pt={2}>
          <Typography px={1} component="h6" variant="h6" fontWeight="regular" color={"#000000"}>
            应用LOGO:
          </Typography>
          <Box
            component="img"
            sx={{
              height: 40,
              width: 40,
              mr: 2,
            }}
            src={brandWhite}
          />
        </Box>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            float: "right",
            borderColor: "#6e6e6e",
          }}
        >
          取消
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#4b26df",
            color: "#ffffff",
            float: "right",
          }}
        >
          确定
        </Button>
      </DialogActions>
      <ErrorModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={`创建App失败，请重试`}
        time={1000}
      />
    </Dialog>
  );
};

CreateAppDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
};

CreateAppDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateAppDialog;
